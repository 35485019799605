<template>
  <div class="flex flex-wrap items-end p-6 lg:p-8 bg-gradient-black-01 rounded-xl ring-1 ring-gray-200/10">
    <h2 class="mr-4 text-2xl leading-tight text-white lg:text-3xl">
      {{ title }}
    </h2>
    <button class="relative order-3 mt-8 ml-auto text-sm pb-0.5 text-white bottom-line-inverted md:order-2 md:mt-0 duration-500"
            :class="field.key === 'all' && location.key === 'all' && type.key === 'all' ? 'opacity-0 pointer-events-none' : ''"
            @click="clearFilter"
    >
      Clear filters
    </button>
    <div class="grid w-full mt-8 md:grid-cols-3 lg:mt-9 gap-y-6 lg:gap-y-0 gap-x-8 md:order-3">
      <Dropdown :items="jobFields"
                :value="field"
                class="z-[12]"
                @change="(item: ComboBoxItemType) => setSelected(item, 'field')"
      />

      <Dropdown :items="locations"
                :value="location"
                class="z-[11]"
                @change="(item: ComboBoxItemType) => setSelected(item, 'location')"
      />

      <Dropdown :items="jobTypes"
                :value="type"
                class="z-10"
                @change="(item: ComboBoxItemType) => setSelected(item, 'type')"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useRouter, useRoute } from 'vue-router'
import useJobFilter from '../../../composables/job-filters'
import type { ComboBoxItemType } from '../../../models/combobox'

interface filterProps {
  title: string
  type: ComboBoxItemType
  location: ComboBoxItemType
  field: ComboBoxItemType
}

const route = useRoute()
const router = useRouter()
const jobFields = useJobFilter('field')
const locations = useJobFilter('location')
const jobTypes = useJobFilter('type')

defineProps<filterProps>()

const clearFilter = () => {
  router.push({
    query: {
      ...route.query,
      field: undefined,
      location: undefined,
      type: undefined
    }
  })
}

defineExpose({
  clearFilter
})

const setSelected = (item: ComboBoxItemType, id: 'field' | 'location' | 'type') => {
  router.push({
    query: {
      ...route.query,
      [id as string]: item.key === 'all' ? undefined : item.key
    }
  })
}
</script>

<style>
.bottom-line-inverted:before,
.bottom-line-inverted:after {
  @apply absolute bottom-0 w-full  h-px  bg-white;
  content: '';
}

.bottom-line-inverted:before {
  @apply left-0 bg-white;
  transition: width 0.4s cubic-bezier(0.22, 0.61, 0.36, 1);
}
.bottom-line-inverted:after {
  @apply w-full bg-transparent right-0;
  transition: 0s;
}

.bottom-line-inverted:hover:before {
  @apply w-0 bg-transparent;
}

.bottom-line-inverted:hover:after {
  @apply w-0 bg-white;
  transition: width 0.4s cubic-bezier(0.22, 0.61, 0.36, 1);
}
</style>
