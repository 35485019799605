<template>
  <div class="fixed top-0 left-0 right-0 z-40 bg-transparent"
       data-nosnippet
  >
    <div class="relative z-40 grid items-center grid-cols-2 px-4 lg:py-3 bg-gray-600 lg:grid-cols-5 backdrop-blur-[10px]"
         :class="{ 'bg-opacity-0 duration-500 delay-150': mobileMenuOpen, 'bg-opacity-95 duration-150': !mobileMenuOpen }"
         :style="{ transform: 'translate3d(0, 0, 0)' }"
    >
      <!-- Outfit7 logo -->
      <div class="flex items-center h-full">
        <NuxtLink href="/"
                  :title="items[0].title"
                  class="flex items-center h-full px-2"
                  @click="mobileMenuOpen = false"
        >
          <span class="sr-only">{{ items[0].title }}</span>
          <SvgOutfit7Logo class="h-5 text-white lg:h-[22px]" />
        </NuxtLink>
      </div>

      <MenuDesktop :items="menuItems"
                   :social="socialItems"
                   :first-level-active-url="firstLevelMenu ? firstLevelMenu.url : '/'"
      />

      <div class="flex justify-end lg:hidden">
        <NuxtLink v-if="jobsCount && firstLevelMenu && firstLevelMenu.slug === 'careers'"
                  href="/careers/jobs"
                  class="flex items-center text-sm text-gray-200 transition"
                  :class="mobileMenuOpen ? 'opacity-0' : 'opacity-100'"
        >
          All Jobs
          <div class="flex items-center justify-center w-5 h-5 ml-1 rounded-full bg-gradient-red">
            <div class="-mt-0.5 text-xs text-white">
              {{ jobsCount }}
            </div>
          </div>
        </NuxtLink>
        <MenuToggleButton :open="mobileMenuOpen"
                          @click="mobileMenuOpen = !mobileMenuOpen"
        />
      </div>
      <!-- Border bottom for desktop menu -->
      <div v-if="secondaryItems.length === 0"
           class="absolute bottom-0 hidden w-full h-px duration-200 border-b md:block border-gray-450"
      />
    </div>

    <Transition enter-active-class="transition duration-200 ease-out"
                enter-from-class="-translate-y-full"
                enter-to-class="translate-y-0"
                leave-active-class="transition duration-150 ease-in"
                leave-from-class="translate-y-0"
                leave-to-class="-translate-y-full"
    >
      <MenuDesktopSubmenu v-if="secondaryItems.length > 0"
                          :items="secondaryItems"
                          :url="route.path"
                          :jobs="jobsCount"
      />
    </Transition>

    <MenuMobile :items="menuItems"
                :social="socialItems"
                :open="mobileMenuOpen"
                @close="mobileMenuOpen = false"
    />
  </div>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import items from '../content/navigation/main.json'
import social from '../content/navigation/social.json'
import type { MenuItemType, MenuSocialItemType } from '../models/menu'
import { useAsyncData, queryContent } from '#imports'
import { SPONTANEOUS_APPLICATION_ID } from '~/constants/common.mjs'

const route = useRoute()
const secondaryItems = ref([])
const mobileMenuOpen = ref(false)
const menuItems = items.slice(1) as MenuItemType[]
const socialItems = social as MenuSocialItemType[]
const firstLevelMenu = ref<MenuItemType>(items[0] as MenuItemType)

const { data: jobsCount } = await useAsyncData('jobs-count', () => queryContent('jobs')
  .where({ id: { $ne: SPONTANEOUS_APPLICATION_ID } })
  .count())

watch(
  () => route.path,
  async newPath => {
    setActiveItem(newPath)
  }
)

const setActiveItem = (path: string) => {
  if (route && route.path && route.params.slug && route.params.slug.length > 0) {
    const paths = path.split('/')
    // paths 0 is always a blank string, due to starting with a /

    if (paths.length > 1) {
      firstLevelMenu.value = items.find(item => item.slug === path.split('/')[1]) as MenuItemType
    }
  }
  else firstLevelMenu.value = items[0]
  if (firstLevelMenu.value && firstLevelMenu.value.children) {
    secondaryItems.value = firstLevelMenu.value.children
  }
  else secondaryItems.value = []
}

setActiveItem(route.path)
</script>
