<template>
  <div ref="container"
       class="relative lg:min-h-[200vh]"
  >
    <div class="absolute w-full h-full">
      <div class="sticky top-0 flex items-center justify-center h-screen overflow-hidden">
        <div class="absolute left-0 right-0 flex items-center justify-center w-full h-full -translate-y-1/2 top-1/2">
          <div class="rounded-full blur-3xl md:blur-2xl md:w-[480px] md:h-[480px] w-[100vw] h-[100vw] duration-150"
               :class="[`bg-${color}`]"
               :style="{
                 transform: `scale3d(${scale}, ${scale}, 1)`,
               }"
          />
        </div>
      </div>
    </div>

    <div class="sticky top-0 flex flex-col pb-16 lg:pb-24"
         :style="{
           paddingTop: `calc(50vh - ${textHeight / 2}px)`,
         }"
    >
      <div class="relative flex flex-col items-center mb-20 top-1/2 lg:mb-40">
        <p ref="textContainer"
           class="px-6 mx-auto text-3xl text-center text-white lg:px-24 md:max-w-2xl lg:max-w-4xl lg:text-5xl lg:leading-tight"
        >
          {{ copy }}
        </p>
        <div v-if="outfit7neo"
             class="flex flex-wrap items-center justify-center gap-4 text-sm mt-14 md:text-base"
        >
          <span class="w-full text-center text-white md:w-auto md:text-left">Published by:</span>
          <SvgOutfit7neoLogo class="h-9" />
        </div>
      </div>

      <Container class="relative w-full">
        <div class="grid gap-6 mb-6 transition duration-500 lg:mb-8"
             :class="[
               cardsVisible ? 'opacity-100 scale-100' : 'opacity-0 scale-90 ',
               cardPlacement === 'vertical' ? 'grid-cols-1' : 'grid-cols-1 md:grid-cols-2',
             ]"
        >
          <CardBase v-for="(card, i) in cards"
                    :key="i"
                    :title="card.title"
                    :copy="card.copy"
                    :cta="card.cta"
                    :logo="card.logo"
                    :background-image="card.img"
                    :background-image-mobile="card.imgMobile"
                    :type="cardPlacement === 'vertical' ? 'product' : 'product-small'"
                    :class="{ 'xs:h-[360px]': cardPlacement === 'horizontal' }"
                    @click="handleClick(card.cta)"
          />
        </div>

        <div v-if="cta"
             class="flex justify-center py-6 lg:py-8"
        >
          <ButtonBase :type="cta.type"
                      :href="cta.href"
                      :label="cta.title"
          />
        </div>
      </Container>
    </div>
  </div>
</template>

<script setup lang="ts">
import { scroll } from 'motion'
import { onMounted, onUpdated, ref } from 'vue'
import { useRoute } from 'vue-router'
import type { CtaType } from '../../models/button'
import { useNuxtApp } from '#imports'

interface GradientCallOutProps {
  copy: string
  cards: {
    title: string
    copy: string
    logo?: string
    cta: CtaType
    img: string
    imgMobile?: string
  }[]
  cta: CtaType
  color?: string
  outfit7neo?: boolean
  cardPlacement?: 'vertical' | 'horizontal'
}

withDefaults(defineProps<GradientCallOutProps>(), {
  cardPlacement: 'horizontal',
  outfit7neo: false,
  color: 'purple-aqua'
})

const { $event } = useNuxtApp()
const route = useRoute()

const textHeight = ref(0)
const cardsVisible = ref(false)
const scale = ref(1)
const textContainer = ref(null)
const container = ref(null)
const scrollEvent = ref(null)

function updateTextHeight() {
  //  text height is needed for sticky text to center
  //  without transform so we can control the
  //  spacing between text and cards
  if (textContainer.value.offsetHeight !== textHeight.value) textHeight.value = textContainer.value.offsetHeight
}

function changeBgSize(progress: number): void {
  if (!window) return

  // treshold on which percentage of container scrolled circle fills the screen
  // textHeight + padding
  if (container.value === null) {
    return
  }

  const fillTreshold = (textHeight.value + (window.innerHeight / 2 - textHeight.value / 2)) / container.value.offsetHeight

  //  calculate progress percentage based on when circle fills the screen
  const percentage = (1 / fillTreshold) * progress

  // We go over the edge to hide the blur and border radius for a simple animation
  const multiplier = 1.6
  const largerDimension = Math.max(window.innerWidth, window.innerHeight)

  // Max scale is calculated based on the width of the screen
  // and the size of the circle
  const maxScale = (largerDimension / 480) * multiplier

  if (progress > fillTreshold) {
    cardsVisible.value = true
  }
  else {
    cardsVisible.value = false
  }

  // Minimum value is 1, max is maxScale,
  // we increase it by a percentage of the difference (maxScale - 1)
  scale.value = percentage >= 1 ? maxScale : Math.max(1, Math.min(maxScale, 1 + (maxScale - 1) * percentage))
}

function handleClick(cta: CtaType) {
  if (!cta || !cta.href) return
  if (route.path.includes('our-work')) return $event('Link', { props: { 'Our work page': cta.href } })
}

onUpdated(() => {
  updateTextHeight()
})

onMounted(() => {
  scrollEvent.value = scroll(
    ({ y }) => {
      changeBgSize(y.progress)
    },
    {
      target: container.value,
      offset: ['start start', 'end end']
    }
  )

  updateTextHeight()
})
</script>
