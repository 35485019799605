<template>
  <div ref="container"
       data-scroll
  >
    <Menu />

    <slot />

    <Footer />

    <CookieModal v-if="showCookieModal" />
  </div>
</template>

<script setup lang="ts">
const container = ref()
const route = useRoute()
const { showCookieModal } = useCookiesConsent()

watch(
  () => route.path,
  async newPath => {
    addRouteClass(newPath)
  }
)

const addRouteClass = (path: string) => {
  const page = path === '/' ? 'homepage' : path.split('/')[1]
  if (container.value) container.value.className = page
}

watch(
  () => container.value,
  async () => {
    addRouteClass(route.path)
  }
)

addRouteClass(route.path)
</script>
