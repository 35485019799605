type CookiesConsentLocalStorageItem = {
  timestamp: number
}

type CookiesConsent = {
  isExpired: boolean
}

const LOCAL_STORAGE_ITEM_KEY = 'cookiesConsent'
const COOKIE_CONSENT_VALIDITY_DAYS = 390

const isLocalStorageSupported = testLocalStorageSupport()

export function useCookiesConsent() {
  const showCookieModal = computed(() => {
    if (!isLocalStorageSupported) {
      return false
    }

    const cookiesConsent = getCookiesConsent()

    return !cookiesConsent || cookiesConsent.isExpired
  })

  return {
    showCookieModal,
    getCookiesConsent,
    setCookiesConsent
  }
}

function getCookiesConsent(): CookiesConsent {
  const localStorageItem = localStorage.getItem(LOCAL_STORAGE_ITEM_KEY)

  if (!localStorageItem) {
    return
  }

  const cookiesConsentLocalStorageItem = JSON.parse(localStorageItem) as CookiesConsentLocalStorageItem

  return {
    isExpired: Date.now() > cookiesConsentLocalStorageItem.timestamp + COOKIE_CONSENT_VALIDITY_DAYS * 86_400_000
  }
}

function setCookiesConsent() {
  const cookiesConsentLocalStorageItem = {
    timestamp: Date.now()
  } as CookiesConsentLocalStorageItem

  localStorage.setItem(LOCAL_STORAGE_ITEM_KEY, JSON.stringify(cookiesConsentLocalStorageItem))
}
