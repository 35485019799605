<template>
  <div ref="cardsContainer"
       class="relative z-10 bg-white"
  >
    <div class="sticky top-0 z-10 flex justify-center w-full lg:h-screen lg:items-center">
      <Container>
        <h2 class="my-20 text-5xl text-center lg:text-8xl lg:mt-0">
          {{ copy }}
        </h2>
      </Container>
    </div>
    <Container class="px-4 pb-16 mx-auto space-y-16 lg:px-0 lg:space-y-0 lg:pb-[100vh]">
      <div v-for="(card, i) in cards"
           :key="i"
           :ref="
             el => {
               cardsRefs.push(el);
             }
           "
           class="max-w-[290px] lg:max-w-sm relative"
           :class="[
             { 'lg:ml-40': i % 5 === 0 },
             { 'ml-auto': i % 5 === 1 },
             { '': i % 5 === 2 },
             { 'ml-auto lg:mr-20': i % 5 === 3 },
             { 'lg:ml-80': i % 5 === 4 },
             !card.cta && card.title === '' && card.copy === '' ? 'z-20 lg:z-0' : 'z-20',
           ]"
      >
        <CardImage :title="card.title"
                   :copy="card.copy"
                   :cta="card.cta"
                   :background-image="card.img"
                   :class="[
                     {
                       'h-[320px] lg:h-[460px]':
                         (card.cta && (card.title !== '' || card.copy !== '')) || (!card.cta && (card.title !== '' || card.copy !== '')),
                     },
                     {
                       'lg:h-[320px] lg:w-[320px]': card.cta && card.title === '',
                     },
                     {
                       'lg:h-[256px]  lg:w-[256px]': !card.cta && card.title === '' && card.copy === '',
                     },
                   ]"
                   class="w-[290px] h-[290px] lg:w-auto"
        />
      </div>
    </Container>
  </div>
</template>

<script setup lang="ts">
import { animate, scroll } from 'motion'
import { onMounted, ref } from 'vue'
import { useScreenSize } from '../../composables/use-screen-size'
import type { CtaType } from '../../models/button'

interface ParallaxCardsProps {
  cards: {
    title: string
    copy: string
    cta: CtaType
    img: string
  }[]
  copy: string
}

defineProps<ParallaxCardsProps>()

const { isDesktop } = useScreenSize()

const cardsRefs = ref([])
const cardsContainer = ref(null)

onMounted(() => {
  if (cardsRefs.value.length > 0 && isDesktop.value) {
    cardsRefs.value.forEach((card, i) => {
      if (i % 2 !== 0) {
        scroll(animate(card, { y: [-200 * i, 100] }), {
          target: cardsContainer.value
        })
      }
    })
  }
})
</script>
