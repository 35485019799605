import { onMounted, ref } from 'vue'
import { Breakpoint } from '~/constants/screen-size'

const isMobile = ref(false)
const isTablet = ref(false)
const isDesktop = ref(true)

export function useScreenSize() {
  onMounted(() => {
    window.addEventListener('resize', update)
    update()
  })

  return {
    isMobile,
    isTablet,
    isDesktop
  }
}

function update() {
  if (window.innerWidth < Breakpoint.TABLET) {
    isMobile.value = true
    isTablet.value = false
    isDesktop.value = false
    return
  }

  if (window.innerWidth < Breakpoint.DESKTOP) {
    isMobile.value = false
    isTablet.value = true
    isDesktop.value = false
    return
  }

  isMobile.value = false
  isTablet.value = false
  isDesktop.value = true
}
