<template>
  <!-- First level desktop menu -->
  <div class="justify-center hidden col-span-3 lg:flex">
    <ol class="inline-flex flex-wrap justify-center space-x-4 font-medium duration-100"
        :class="{
          'text-gray-100 hover:text-gray-250': firstLevelActiveUrl === '/',
          'text-gray-250': firstLevelActiveUrl !== '/',
        }"
    >
      <li v-for="link in items"
          :key="link.id"
          class=""
      >
        <NuxtLink class="inline-flex items-center px-4 py-1.5 duration-100 hover:text-gray-100"
                  :class="{
                    'text-gray-100': firstLevelActiveUrl !== '' && link.url === firstLevelActiveUrl,
                  }"
                  :href="link.url"
        >
          {{ link.title }}
        </NuxtLink>
      </li>
    </ol>
  </div>

  <!-- Desktop Social menu -->
  <div class="hidden space-x-2 lg:flex lg:justify-end">
    <NuxtLink v-for="link in social"
              :key="link.id"
              class="p-2 text-gray-200 duration-150 opacity-50 hover:text-gray-100 hover:opacity-100"
              target="_blank"
              rel="noreferrer"
              :to="link.url"
              @click="handleClick(link.title)"
    >
      <span class="sr-only">{{ link.title }}</span>
      <component :is="`Icon${link.icon}`"
                 class="w-5 h-5"
      />
    </NuxtLink>
  </div>
</template>

<script setup lang="ts">
import type { MenuItemType, MenuSocialItemType } from '../../models/menu'
import { useNuxtApp } from '#imports'

interface MenuDesktopProps {
  items: Array<MenuItemType>
  social: Array<MenuSocialItemType>
  firstLevelActiveUrl: string
}

defineProps<MenuDesktopProps>()

const { $event } = useNuxtApp()

function handleClick(linkTitle: string) {
  $event('Link', { props: { Header: linkTitle } })
}
</script>
