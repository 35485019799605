export function testLocalStorageSupport() {
  if (import.meta.client) {
    try {
      const x = '__storage_test__'
      localStorage.setItem(x, x)
      localStorage.removeItem(x)
      return true
    }
    catch (error) {
      console.error(error)
      return false
    }
  }
}
